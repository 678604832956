.suggestions {
  position: absolute;
  background-color: white;
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: calc(18rem + 6px);
  overflow-y: auto;
  padding-left: 0;
}

.suggestions li {
  padding: 0.75rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: $color-accent;
  color: white;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}
