.block-title {
  margin-top: 3rem;
}

.block-table {
  background-color: $white;
  border-bottom: $border-gray;

  @media (min-width: 768px) {
    border-left: $border-gray;
    border-right: $border-gray;
  }

  .table {
    margin: 0;

    thead {
      background-color: $color-border;
    }
    th, td {
      &.team-name {
        max-width: 35vw;
      }

      &.number {
        text-align: center;
      }
    }
  }
}