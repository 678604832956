// Import custom variables first to override bootstrap variables
@import './variables';

@import "../../node_modules/bootstrap/scss/bootstrap";

@import './components/_autocomplete';
@import './components/_blocktable';

h1, h2, h3, h4 {
  font-family: Raleway, Helvetica, sans-serif;
}

table {
  margin-top: 1rem;
}

.table th, .table td {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.container-fluid {
  margin-bottom: 120px;
}

.container-relative {
  position: relative;
}

.navbar-link {
  padding-right: 1rem
}

.navbar-button:hover {
  cursor: pointer;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
}

.message-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  height: 0;
  z-index: 1000;
}

.alert-sticky {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  z-index: 1000;
}

.outline {
  border-radius: 0.5rem;
  border-width: thin;
  border-style: solid;
  border-color: gray;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.input-field {
  min-width: 4rem;
  max-width: 6rem;
}

.input-comment {
  min-width: 16rem;
  max-width: 24rem;
}

// Spinner overlay

.overlay {
  background-color: rgba($color: #555555, $alpha: 0.2);
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  
  // position the content in center
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  

  transition: opacity 1s ease-in;
}

.overlay-center {
  width: 64px;
  height: 64px;
}

.spinner-large {
  width: 3rem;
  height: 3rem;
}

// Fade transitions for CSSTransition

.fade-enter {
  opacity: 0
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.4s ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}